import dateService from '../../../services/date.service';
import { formatAmount } from '../../../utils/number-utils';
import './dashboard-page.part.summary.scss';

interface DashobardPagePartSummaryProps {
    transactionsNo: number;
    amount: number;
    commission: number
}

const DashboardPagePartSummary: React.FC<DashobardPagePartSummaryProps> = ({ transactionsNo, amount, commission}) => {
    return (
        <div className='dashboard-page-part-summary flex-column flex-gap-10 flex-align-center-center'>
            <div className='description'>
               Total Transactions: <span className='value'> {transactionsNo}</span>
            </div>
            <div className='description'>
               Total Amount: <span className='value'> ${formatAmount(amount)}</span>
            </div>
            <div className='description'>
               Data for   <span className='value'>{dateService.currentDate()}</span>
            </div>
        </div>
    )
}

export default DashboardPagePartSummary