import { Outlet } from "react-router-dom";
import SideMenu from "../../components/side-menu";
import { ReactComponent as BurgerMenu } from '../../assets/icons/burger-menu.svg';
import './app-layout.scss';
import { useState } from "react";


const AppLayout = () => {
        const [toggleMenu, setToggleMenu] = useState(false);

        const handleToggleMenu = () => {
                setToggleMenu(!toggleMenu);
        }
        return (
                <div className="app-layout">
                   <div className="mobile-header flex-row flex-align-start-center">
                        <BurgerMenu onClick={handleToggleMenu}/>
                   </div>     
                        <div className="side-menu-placeholder"></div>
                        <SideMenu isOpen={toggleMenu} toggleMenu={handleToggleMenu}/>
                        <Outlet />
                </div>)
}

export default AppLayout;