import AppInput from './form/base/app-input';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import AppSelect from './form/base/app-select';
import './table-filters.scss';
import { useEffect, useState } from 'react';
import { apiService } from '../services/api.service';
import { LabelValue } from '../types/label-value.types';
import { StatusType } from '../types/statistics.types';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/store';
import { UserState } from '../store/user.slice';

interface TableFiltersProps {
    onFiltersChange: (filters: {
        traceId?: string;
        merchantId?: string;
        midId?: string;
        processorId?: string;
        customerCardType?: string;
        status?: string;
    }) => void;
    resetTrigger: boolean;
    showStatus?: boolean
}

const TableFilters: React.FC<TableFiltersProps> = ({ onFiltersChange, resetTrigger, showStatus = true }) => {
    const [merchantOptions, setMerchantOptions] = useState<LabelValue[]>([]);
    const [processorsOptions, setProcessorsOptions] = useState<LabelValue[]>([]);
    const [cardBrandsOptions, setCardBrandsOptions] = useState<LabelValue[]>([]);
    const [midOptions, setMidOptions] = useState<LabelValue[]>([]);
    const [selectedMerchant, setSelectedMerchant] = useState<string | null>(null);
    const [selectedCard, setSelectedCard] = useState<string | null>(null);
    const [selectedProcessor, setSelectedProcessor] = useState<string | null>(null);
    const [selectedMID, setSelectedMID] = useState<string | null>(null);
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string>('');
    const { user } = useSelector<AppStore, UserState>((state) => state.user)
    
    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;


    const statusOptions = Object.values(StatusType).map((status) => ({
        label: status,
        value: status,
    }));

    useEffect(() => {
        if (user?.id && isMerchant) {
            setSelectedMerchant(user.id);
        }
    }, [user])

    

    useEffect(() => {
        setSearchValue('');
        if (!isMerchant) {
        setSelectedMerchant(null);
        }
        setSelectedProcessor(null);
        setSelectedCard(null);
        setSelectedStatus(null);
        setSelectedMID(null)
    }, [user,resetTrigger]);

    useEffect(() => {
        setSelectedMID('');
        if (!isMerchant && selectedMerchant) {
        apiService.getMidsByMerchantAndProcessor({ merchantId: selectedMerchant, processorId: selectedProcessor })
            .then((response) => {
                const transformedMids = response.data.map((mid: any) => ({
                    label: mid.midName || mid.midCode || 'Unknown',
                    value: mid.id,
                }));
                setMidOptions([{
                    label: 'Reset',
                    value: '',
                }, ...transformedMids]);
            })
            .catch((error) => console.error('Error fetching mids:', error));
        }

    }, [selectedMerchant, selectedProcessor])

    useEffect(() => {
        if (isMerchant && user?.id) {
            setSelectedMerchant(user.id);
    
            apiService.getMidsByMerchantAndProcessor({ merchantId: user?.id, processorId: selectedProcessor })
                .then((response) => {
                    const transformedMids = response.data.map((mid: any) => ({
                        label: mid.midName || mid.midCode || 'Unknown',
                        value: mid.id,
                    }));
                    setMidOptions([{
                        label: 'Reset',
                        value: '',
                    }, ...transformedMids]);
                })
                .catch((error) => console.error('Error fetching mids:', error));
        }
    }, [isMerchant, user?.id, selectedProcessor]);

    useEffect(() => {
        setTimeout(() => {
        if (!isMerchant) {
            apiService
                .getAllMerchants()
                .then((response) => {
                    const transformedMerchants = response.data.map((merchant: any) => ({
                        label: merchant.lastName || merchant.lastName || 'Unknown',
                        value: merchant.id,
                    }));
                    setMerchantOptions([{
                        label: 'Reset',
                        value: '',
                    }, ...transformedMerchants]);
                })
                .catch((error) => console.error('Error fetching merchants:', error));
        }

        apiService
            .getAllProcessors()
            .then((response) => {
                const transformedProcessors = response.data.map((processor: any) => ({
                    label: processor.processorName || processor.processorType || 'Unknown',
                    value: processor.id,
                }));
                setProcessorsOptions([{
                    label: 'Reset',
                    value: '',
                }, ...transformedProcessors]);
            })
            .catch((error) => console.error('Error fetching processors:', error));


        apiService
            .getAllCardTypes()
            .then((response) => {
                const transformedCards = response.data.map((card: any) => ({
                    label: card || 'Unknown',
                    value: card,
                }));
                setCardBrandsOptions([{
                    label: 'Reset',
                    value: '',
                }, ...transformedCards]);
            })
            .catch((error) => console.error('Error fetching card types:', error));
        }, 500)
    }, [user])


    useEffect(() => {
        onFiltersChange({
            traceId: searchValue,
            midId: selectedMID || undefined,
            merchantId: selectedMerchant || undefined,
            processorId: selectedProcessor || undefined,
            customerCardType: selectedCard || undefined,
            status: selectedStatus || undefined,
        });
    }, [searchValue, selectedMID, selectedMerchant, selectedCard, selectedProcessor, selectedStatus]);

    return (
        <div className='table-filters flex-row flex-wrap flex-gap-15'>
            <AppInput
                theme='filter'
                icon={<SearchIcon />}
                value={searchValue}
                placeholder='Search'
                onChange={(event) => {
                    setSearchValue(event.target.value)
                }}
            />
            {!isMerchant &&
                <AppSelect
                    theme='filter'
                    value={selectedMerchant}
                    options={merchantOptions}
                    placeholder='Select Merchant'
                    onChange={(value) => setSelectedMerchant(value)}
                />
            }
            <AppSelect
                theme='filter'
                value={selectedCard}
                options={cardBrandsOptions}
                placeholder='Select Card Brand'
                onChange={(value) => setSelectedCard(value)}
            />
            <AppSelect
                theme='filter'
                value={selectedProcessor}
                options={processorsOptions}
                placeholder='Select Processor'
                onChange={(value) => setSelectedProcessor(value)}
            />
            <AppSelect
                theme='filter'
                value={selectedMID}
                options={midOptions}
                placeholder='Select MID'
                onChange={(value) => setSelectedMID(value)}
            />
            {showStatus &&
                <AppSelect
                    theme='filter'
                    value={selectedStatus}
                    options={[{
                        label: 'Reset',
                        value: '',
                    }, ...statusOptions]}
                    placeholder='Select Status'
                    onChange={(value) => setSelectedStatus(value)}
                />
            }
        </div>
    )
}

export default TableFilters;