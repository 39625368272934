import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { AppStore } from '../../store/store';
import { UserState } from '../../store/user.slice';

interface MerchantGuardProps {
    children: ReactNode;
}

const MerchantGuard: React.FC<MerchantGuardProps> = ({ children }) => {
    const { user } = useSelector<AppStore, UserState>((state) => state.user);

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view"));

    if (isMerchant) {
        return <Navigate to="/app/dashboard" replace />;
    } else {
        return <>{children}</>;
    }
};

export default MerchantGuard;
