import React from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import Button from '../../../../components/button';
import { ReactComponent as RemoveOutlineIcon } from '../../../../assets/icons/remove-outline.svg';
import { EditMerchantForm, CommissionType, CommissionValueType } from './edit-merchant.part.form';
import AddMerchantPartFormCommissionField from './edit-merchant.part.form.commission-field';
import './edit-merchant.part.form.add-processor.scss';

interface MIDFieldProps {
    form: UseFormReturn<EditMerchantForm>;
    processorIndex: number;
    midIndex: number;
    removeMID: (index: number) => void;
    mid: any;
}

const MIDField: React.FC<MIDFieldProps> = ({ form, processorIndex, midIndex, removeMID, mid }) => {
    const { fields: commissionFields, remove: removeTransactionCommission } = useFieldArray({
        control: form.control,
        name: `midList.${processorIndex}.midsDetails.${midIndex}.commissions` as const,
    });
    const isValid = form.watch(`midList.${processorIndex}.midsDetails.${midIndex}.valid`);

    return (
        <div className="mid-fields flex-column flex-gap-30">
            <div className="flex-row flex-gap-20 flex-align-start-start">
                <AppFormInput
                    control={form.control}
                    name={`midList.${processorIndex}.midsDetails.${midIndex}.midName` as const}
                    placeholder="MID name"
                    validations={{ required: true }}
                />
                <div className=" flex-1-form">
                    <AppFormInput
                        control={form.control}
                        name={`midList.${processorIndex}.midsDetails.${midIndex}.trashHoldLimit` as const}
                        type="number"
                        placeholder="Cap MID - $"
                        validations={{
                            required: true, min: {
                                value: 0,
                                message: 'Commission value cannot be negative',
                            },
                        }}
                        min={0}
                        hasCurrency={true}
                    />

                </div>
                <div>

                </div>

            </div>
            <div className="flex-row flex-gap-20 flex-align-start-center">
                <AppFormInput
                    control={form.control}
                    name={`midList.${processorIndex}.midsDetails.${midIndex}.midCode` as const}
                    placeholder="Account Code"
                    validations={{ required: true }}
                />
                {mid.new && (
                    <RemoveOutlineIcon
                        width={35}
                        height={35}
                        fill='white'
                        className="remove-icon"
                        onClick={() => removeMID(midIndex)}
                    />
                )}
                <AppFormInput
                    control={form.control}
                    name={`midList.${processorIndex}.midsDetails.${midIndex}.midDescriptor` as const}
                    placeholder="MID Descriptor"
                />
                {!mid.new && (isValid ? (
                    <Button
                        theme="secondary"
                        label="Invalidate"
                        onClick={() =>
                            form.setValue(
                                `midList.${processorIndex}.midsDetails.${midIndex}.valid`,
                                false
                            )
                        }
                    />

                ) : (
                    <Button
                        label="Validate"
                        onClick={() =>
                            form.setValue(
                                `midList.${processorIndex}.midsDetails.${midIndex}.valid`,
                                true
                            )
                        }
                    />
                ))}
            </div>
            <div className="flex-row flex-gap-20 flex-wrap">
                {commissionFields.map((field, index) => (
                    <AddMerchantPartFormCommissionField
                        key={field.id}
                        form={form}
                        label={`Transaction Commission`}
                        secondaryLabel={field.type}
                        commissionName={`midList.${processorIndex}.midsDetails.${midIndex}.commissions.${index}` as const}
                        removeCommission={() => removeTransactionCommission(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MIDField;
