import axios, { AxiosInstance, AxiosResponse } from 'axios';
import qs from 'qs';
import { attachAxiosApiInterceptors, attachAxiosLogInterceptors } from '../utils/axios-utils';
import { KeyCloakLoginResponse } from './key-cloak-api.service.types';
import { Dispatch } from '@reduxjs/toolkit';

class KeyCloakApiService {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: 'https://admin.paybridge.services/realms/PaymentGateway/protocol/openid-connect',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
    attachAxiosLogInterceptors(this.client);
    attachAxiosApiInterceptors(this.client)
  }

  login(
    data: {username: string; password: string},
  ): Promise<AxiosResponse<KeyCloakLoginResponse>> {
    let payload = qs.stringify({
      ...data,
      grant_type: 'password',
      client_id: 'paymentgateway-client',
      client_secret: 'cGpORR1yyQrn57As19phmyaI5kMwcmVd',
      scope: 'openid'
    });

    return this.client.post<KeyCloakLoginResponse>(
      `/token`,
      payload
    );
  }

  refreshToken(refreshToken: string): Promise<AxiosResponse<KeyCloakLoginResponse>> {
    let payload = qs.stringify({
      client_id : 'paymentgateway-client',
      grant_type: 'refresh_token',
      client_secret: 'cGpORR1yyQrn57As19phmyaI5kMwcmVd',
      refresh_token: refreshToken
    })
    return this.client.post<KeyCloakLoginResponse>(
      `/token`,
      payload
    );
  }
}

const keyCloakApiService = new KeyCloakApiService();
export { keyCloakApiService };