import { Navigate } from "react-router-dom";
import AuthLayout from "./auth-page.layout";
import { useSelector } from "react-redux";
import { AppStore } from "../../store/store";
import { UserState } from "../../store/user.slice";

const AuthGuard = () => {
    const { user } = useSelector<AppStore, UserState>((state) => state.user )


     return user?.id ? <Navigate to='/app' /> : <AuthLayout />;
}

export default AuthGuard; 