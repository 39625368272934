import React from "react";
import { ReactComponent as CloseIcon } from '../assets/icons/error-outline.svg';
import "./app-modal-transaction-logs.scss";
import { TransactionLog } from "../types/transaction.types";
import dateService from "../services/date.service";

interface AppModalProps {
  logs: TransactionLog[];
  cancelAction: () => void;
  isVisible: boolean;
}

const AppModalLogs: React.FC<AppModalProps> = ({
    logs,
  cancelAction,
  isVisible,
}) => {
  if (!isVisible) return null;
  

  return (
    <div className="app-modal-transaction-logs-overlay">
      <div className="app-modal flex-column flex-gap-30">
        <div className="title">
            Logs 
        </div>
        <div className="content flex-column flex-gap-30 flex-align-start-center">
            {logs.map((log) => (
                <>
                <div className="log flex-column flex-gap-10 flex-align-start-start">
                    <div>Initiator: <span>{log.initiator}</span></div>
                    <div>New Status: <span>{log.newStatus}</span></div>
                    <div>Info: <span>{log.processorInternalStatus}</span></div>
                    <div>Date: <span>{log.date && dateService.formatDateForTable(log.date)}</span></div>
                </div>
                <div className="modal-separator"></div>
                </>
            )) }
        </div>
        <div className="modal-actions">
          <button
            className="btn btn-cancel"
            onClick={cancelAction}> Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppModalLogs;
