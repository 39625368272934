import { FieldValues, useForm } from "react-hook-form";
import AppFormInput from "../../components/form/app-form-input";
import './auth-page.login.scss';
import Button from "../../components/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { authService } from "../../services/auth.service";
import { apiService } from "../../services/api.service";
import { userSliceActions } from "../../store/user.slice";

interface LoginForm extends FieldValues {
    username: string;
    password: string;
}

const LoginPage: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const form = useForm<LoginForm>({
        mode: 'onTouched',
        defaultValues: {}
    });

    const onSubmit = async (data: LoginForm) => {
        setErrorMessage('');
        setLoading(true);
        try {
            const loginResult = await authService.login(data);
            
            const responseUserData = await apiService.getUserDetails(loginResult.userId);
            
            dispatch(userSliceActions.saveUser({
                ...loginResult,
                ...responseUserData.data
            }));
            navigate('/app');
        } catch (e: any) {
            if (e.response && e.response.status === 401) {
                setErrorMessage('Incorrect username or password.');
            } else {
                setErrorMessage('An error occurred. Please try again.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); 
            form.handleSubmit(onSubmit)();
        }
    };

    return (
        <div className="login-page-container flex-column flex-align-center-center flex-gap-20">
            {errorMessage && (
                <div className="error-message" style={{ color: 'red' }}>
                    {errorMessage}
                </div>
            )}
            <form onSubmit={form.handleSubmit(onSubmit)} onKeyDown={handleKeyDown} className="flex-column flex-align-center-center flex-gap-20 width-100">
                <AppFormInput
                    theme="auth"
                    name="username"
                    control={form.control}
                    label="Email"
                    validations={{ required: true }}
                />

                <AppFormInput
                    theme="auth"
                    name="password"
                    control={form.control}
                    label="Password"
                    validations={{ required: true }}
                    type="password"
                />

                <div className="buttons-container flex-column flex-align-center-center flex-gap-30 width-100">
                    <button type="submit" style={{ display: 'none' }}></button>

                    <Button
                        theme="auth"
                        label="Log In"
                        disabled={!form.formState.isValid || loading}
                        onClick={form.handleSubmit(onSubmit)} 
                    ></Button>

                    <div className="reset-password-link">
                        <p>Forgot password?{' '}
                            <span>Reset It!</span>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LoginPage;
