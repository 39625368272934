import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import StatisticSection from "../../../components/statistics-section";
import StatisticsSectionBarChartType from "../../../components/statistics-section.bar-chart-type";
import { useEffect, useState } from "react";
import { apiService } from "../../../services/api.service";
import {  getRandomColor } from "../../../types/statistics.types";
import { useSelector } from "react-redux";
import { AppStore } from "../../../store/store";
import { UserState } from "../../../store/user.slice";
import { colorsPalette } from "../../../utils/colors-utils";

const ProcessorPage = () => {
    const navigate = useNavigate();
    const [ processorsStatistic, setProcessorsStatistic] = useState();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    useEffect(() => {
        
        apiService.getProcessorStatistics()
        .then((response) => {
            const transformedData = response.data.map((item: any, index: number) => { 
                return ({
                color: colorsPalette[index],
                name: item.name,
                type: item.type,
                quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
            })});
            setProcessorsStatistic(transformedData);
        })
        .catch((error) => console.error('Error fetching processors:', error));
    }, [user])
    return (
        <div className="merchants-page page-content">
            <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Processors 
                </div>
                {/* <Button
                    label="Add Processor"
                    onClick={() => navigate('add-processor')}
                /> */}
            </div>
            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processorsStatistic || []}/>                    
            </StatisticSection>
        </div>
    )
}

export default ProcessorPage;