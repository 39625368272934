import { useNavigate } from 'react-router-dom';
import TimeFilters from '../../../../components/time-filters';
import './reports-time-page.scss';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { apiService } from '../../../../services/api.service';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';
import { getColorByStatusType, getColorByCardBrandType } from '../../../../types/statistics.types';
import StatisticSection from '../../../../components/statistics-section';
import StatisticsSectionBarChartType from '../../../../components/statistics-section.bar-chart-type';
import StatisticsSectionChartType from '../../../../components/statistics-section.chart-type';
import { colorsPalette } from '../../../../utils/colors-utils';

const ReportsTimePage: React.FC = () => {
    const navigate = useNavigate();
    const [ startDate, setStartDate ] = useState<string>();
    const [ endDate, setEndDate ] = useState<string>();
    const [transactionsStatus, setTransactionsStatus] = useState();
    const [transactionsNumber, setTransactionsNumber ] = useState();
    const [cardTypes, setCardTypes] = useState();
    const [processors, setProcessors] = useState();
    const [merchants, setMerchants] = useState();
    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;


    useEffect(() => {
        const merchantId = isMerchant ? user?.id : undefined;

        apiService.getTransactionsSummary({startDate, endDate,merchantId})
        .then((response) => {setTransactionsNumber(response.data.transactionCount)})
        .catch((error) => console.error('Error fetching transactions number:', error));


        apiService.getTransactionsStatusStatistics({startDate, endDate,merchantId})
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByStatusType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setTransactionsStatus(transformedData);
            })
            .catch((error) => console.error('Error fetching transactions status:', error));

        apiService.getCardTypeStatistics({startDate, endDate,merchantId})
            .then((response) => {
                const transformedData = response.data.map((item: any) => ({
                    color: getColorByCardBrandType(item.name),
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setCardTypes(transformedData);
            })
            .catch((error) => console.error('Error fetching card types:', error));

        apiService.getProcessorStatistics({startDate, endDate,merchantId})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => { 
                    return ({
                    color: colorsPalette[index],
                    name: item.name,
                    type: isMerchant ? null :  item.type,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                })});
                setProcessors(transformedData);
            })
            .catch((error) => console.error('Error fetching processors:', error));
            
        if(!isMerchant && user) {
        apiService.getMerchantStatistics({startDate, endDate})
            .then((response) => {
                const transformedData = response.data.map((item: any, index: number) => ({
                    id: item.id,
                    color: colorsPalette[index],
                    name: item.name,
                    quantitativePercent: item.quantityAndQuality?.quantitativePercent || 0,
                    quantitativeValue: item.quantityAndQuality?.quantitativeValue || 0,
                    qualitativePercent: item.quantityAndQuality?.qualitativePercent || 0,
                    qualitativeValue: item.quantityAndQuality?.qualitativeValue || 0,
                }));
                setMerchants(transformedData);
            })
            .catch((error) => console.error('Error fetching merchants:', error));
        }
    }, [user?.id, startDate, endDate]);

    const handleMerchantNavigate = (id: string) => {
        navigate(`/app/merchants/merchant/${id}`);
    }


    const handleDataUpdate = (startDate?: string, endDate?: string) => {
        setStartDate(startDate);
        setEndDate(endDate);
    }

    return (
        <div className='reports-time-page page-content'>
              <div className="page-header flex-row flex-gap-20 flex-align-start-center">
                <div className='page-title'>
                    Reports Time          
                </div>
            </div> 
            <div>
                <TimeFilters transactionsNo={transactionsNumber} handleFilters={handleDataUpdate}/>
            </div>
            <StatisticSection title='Status Statistics'>
                <StatisticsSectionChartType data={transactionsStatus || []} />
            </StatisticSection>
            <StatisticSection title='Card Brand Statistics'>
                <StatisticsSectionChartType data={cardTypes || []} />
            </StatisticSection>
            <StatisticSection title='Processors Statistics'>
                <StatisticsSectionBarChartType data={processors || []} />
            </StatisticSection>
            {!isMerchant &&
            <StatisticSection title='Merchants Statistics'>
                <StatisticsSectionBarChartType data={merchants || []} onNavigate={handleMerchantNavigate} />
            </StatisticSection>
            }
        </div>
    )
}

export default ReportsTimePage