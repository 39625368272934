import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

class DateService {
    private readonly timeZone: string = 'Asia/Singapore';

     getToday(): { startDate: string; endDate: string } {
        const today = dayjs().tz(this.timeZone).format('YYYY-MM-DD');
        return { startDate: today, endDate: today };
    }

    getYesterday(): { startDate: string; endDate: string } {
        const yesterday = dayjs().tz(this.timeZone).subtract(1, 'day').format('YYYY-MM-DD');
        return { startDate: yesterday, endDate: yesterday };
    }

     getThisMonth(): { startDate: string; endDate: string } {
        const startDate = dayjs().tz(this.timeZone).startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs().tz(this.timeZone).endOf('month').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

    getLastMonth(): { startDate: string; endDate: string } {
        const startDate = dayjs().tz(this.timeZone).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        const endDate = dayjs().tz(this.timeZone).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

   getThisYear(): { startDate: string; endDate: string } {
        const startDate = dayjs().tz(this.timeZone).startOf('year').format('YYYY-MM-DD');
        const endDate = dayjs().tz(this.timeZone).endOf('year').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

   getLastYear(): { startDate: string; endDate: string } {
        const startDate = dayjs().tz(this.timeZone).subtract(1, 'year').startOf('year').format('YYYY-MM-DD');
        const endDate = dayjs().tz(this.timeZone).subtract(1, 'year').endOf('year').format('YYYY-MM-DD');
        return { startDate, endDate };
    }

    formatDate(date: Dayjs | null )  {
          return date ? date.tz(this.timeZone).format('YYYY-MM-DD') : undefined;
    } 

    currentDate() {
        return dayjs().tz('Asia/Singapore').format('DD-MM-YYYY');
    }

    currentDateTime() {
        return dayjs().tz('Asia/Singapore').format('DD-MM-YYYY, HH:mm');
    }

   formatDateForTable(date: string): string {
        const dateObj = new Date(date); 
    
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); 
        const year = dateObj.getFullYear();
    
        return `${day}.${month}.${year}, ${hours}:${minutes} `;
    }
}

const dateService = new DateService;

export default dateService;
