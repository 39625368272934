import { Statistic } from "../types/statistics.types";
import BarChart from "./bar-chart";
import CardLegendItem from "./card-legend-item";
import LegendItem from "./legend-item";
import './statistics-section.bar-chart-type.scss';
interface StatisticsSectionChartTypeProps {
    data: Statistic[];
    onNavigate?: (id:string) => void;
}

const StatisticsSectionBarChartType: React.FC<StatisticsSectionChartTypeProps> = ({ data, onNavigate }) => {

    const quantitativeSegments = data.map((statistic) => ({
        percent: statistic.quantitativePercent,
        color: statistic.color,
        label: `${statistic.quantitativePercent}%`,
    }));

    const qualitativeSegments = data.map((statistic) => ({
        percent: statistic.qualitativePercent,
        color: statistic.color,
        label: `${statistic.qualitativePercent}%`,
    }));

    return (
        <div className='statistic-section-bar-chart-type flex-column flex-align-start-start flex-gap-30'>
            <div className="flex-column flex-gap-20 width-100">
                <BarChart segments={quantitativeSegments} label="Quantitative" />
                <BarChart segments={qualitativeSegments} label="Qualitative" />
            </div>
            <div className='flex-row flex-gap-20 width-100 flex-wrap'>
                {data.map(statistic =>
                    <div
                        key={statistic.color}
                        onClick={() => {
                            if (onNavigate) onNavigate(statistic.id);
                        }}
                         
                    >
                        <CardLegendItem style={ {className: onNavigate ? 'hover-enabled' : ''}}>
                            <LegendItem
                                id={statistic.id}
                                color={statistic.color}
                                name={statistic.name}
                                type={statistic.type}
                                quantitativePercent={statistic.quantitativePercent}
                                quantitativeValue={statistic.quantitativeValue}
                                qualitativePercent={statistic.qualitativePercent}
                                qualitativeValue={statistic.qualitativeValue}
                            />
                        </CardLegendItem>
                    </div>
                )}
            </div>
        </div>
    );
};

export default StatisticsSectionBarChartType;