import { ReactComponent as ExcelIcon } from '../../../assets/icons/excel.svg';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf.svg';
import Button from '../../../components/button';
import { apiService } from '../../../services/api.service';
import { TransactionFilters } from '../../../services/api.service-types';
import { errorService } from '../../../services/error.service';
import './transactions-page.part.filtered-data-item.scss';

interface TransactionsPartFilteredDataItemProps {
    total: number;
    noTransactions: number;
    filters: Partial<TransactionFilters>;
}

const TransactionsPartFilteredDataItem: React.FC<TransactionsPartFilteredDataItemProps> = ({
    total,
    noTransactions,
    filters
}) => {

    const handleDownloadExcel = async () => {
        try {
            const response = await apiService.generateExcel(filters);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            errorService.showApiError(error);
            console.error('Failed to download Excel file:', error);
        }
    };

    const handleDownloadPdf = async () => {
        try {
            const response = await apiService.generatePdf(filters);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'transactions.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error: any) {
            errorService.showApiError(error)
        }
    };
    return (
        <div
            className={`transactions-part-filtered-data-item  flex-column  flex-gap-20 flex-1`}
        >
            {/* <div className='description'>
                Total Filtered Amount: <span className='value'> ${total.toLocaleString(undefined, { minimumFractionDigits: 2 })}</span>
            </div> */}
            <div className='description'>
                Number of Filtered Transactions: <span className='value'>{noTransactions}</span>
            </div>
            <div className='flex-row flex-align-space-between-center flex-gap-5'>
                <Button
                    theme="accent"
                    label="Download Excel"
                    icon={ExcelIcon}
                    onClick={handleDownloadExcel} 
                />
                <Button
                    theme="accent"
                    label="Download PDF"
                    icon={PdfIcon}
                    onClick={handleDownloadPdf} 
                />
            </div>
        </div>
    );
};

export default TransactionsPartFilteredDataItem;
