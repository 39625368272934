import { ReactComponent as PayBridgeIcon } from '../assets/icons/logo-black.svg';
import { ReactComponent as XChargerIcon } from '../assets/icons/XCharger.svg';
import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as TransactionsIcon } from '../assets/icons/transactions.svg';
import { ReactComponent as MerchantsIcon } from '../assets/icons/merchants.svg';
import { ReactComponent as ProcessorsIcon } from '../assets/icons/processors.svg';
import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './side-menu.scss';
import { useDispatch } from 'react-redux';
import { userSliceActions, UserState } from '../store/user.slice';
import { useSelector } from 'react-redux';
import { AppStore } from '../store/store';
import dateService from '../services/date.service';


interface MenuItem {
    label: string;
    path: string;
    icon?: ReactNode;
    secondaryItems?: MenuItem[]
}

const menuItemsAdmin: MenuItem[] = [
    {
        label: 'Dashboard',
        path: 'dashboard',
        icon: <DashboardIcon />,
        secondaryItems: [
            {
                label: 'Reports Time',
                path: 'reports-time',
            },
            {
                label: 'Reports Merchant',
                path: 'reports-merchant',
            },
            {
                label: 'Reports Customer',
                path: 'reports-customer',
            },
            {
                label: 'Reports Processor',
                path: 'reports-processor',
            },
            {
                label: 'Reports Status',
                path: 'reports-status',
            },
            {
                label: 'Reports Card Brand',
                path: 'reports-card-brand',
            },
            // {
            //     label: 'Reports Commission',
            //     path: 'reports-commission',
            // }
        ]
    },
    {
        label: 'Transactions',
        path: 'transactions',
        icon: <TransactionsIcon />,
        secondaryItems: [
            {
                label: 'Disputes',
                path: 'disputes',
            },
            {
                label: 'Chargebacks',
                path: 'chargebacks',
            },
            {
                label: 'Refunds',
                path: 'refunds',
            },
        ]
    },
    {
        label: 'Merchants',
        path: 'merchants',
        icon: <MerchantsIcon />,
    },
    {
        label: 'Processors',
        path: 'processors',
        icon: <ProcessorsIcon />,
    }
]

const generateMenuItemsMerchant = (userId: string): MenuItem[] => [
    {
        label: 'Dashboard',
        path: 'dashboard',
        icon: <DashboardIcon />,
        secondaryItems: [
            {
                label: 'Reports Time',
                path: 'reports-time',
            },
            {
                label: 'Reports Processor',
                path: 'reports-processor',
            },
            {
                label: 'Reports Status',
                path: 'reports-status',
            },
            {
                label: 'Reports Card Brand',
                path: 'reports-card-brand',
            },
        ],
    },
    {
        label: 'Transactions',
        path: 'transactions',
        icon: <TransactionsIcon />,
        secondaryItems: [
            {
                label: 'Disputes',
                path: 'disputes',
            },
            {
                label: 'Chargebacks',
                path: 'chargebacks',
            },
            {
                label: 'Refunds',
                path: 'refunds',
            },
        ],
    },
    {
        label: 'MIDs',
        path: `merchants/merchant/${userId}`,
        icon: <MerchantsIcon />,
    },
];


interface SideMenuProp {
    isOpen: boolean;
    toggleMenu: () => void;
}


const SideMenu: React.FC<SideMenuProp> = ({ isOpen, toggleMenu }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [activePath, setActivePath] = useState<string | null>(null);
    const { user } = useSelector<AppStore, UserState>((state) => state.user);
    const [currentDateTime, setCurrentDateTime] = useState(dateService.currentDateTime());

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;

    const isAdmin = user?.roles?.includes("pg_admin") ?? false;



    const menuItems: MenuItem[] = isAdmin
        ? menuItemsAdmin
        : isMerchant && user?.id
            ? generateMenuItemsMerchant(user.id)
            : [];


    useEffect(() => {
        const updateDateTime = setInterval(() => {
            setCurrentDateTime(dateService.currentDateTime());
        }, 60000);

        return () => clearInterval(updateDateTime);
    }, [])

    useEffect(() => {
        const currentPath = location.pathname.replace(`/app/`, '');
        let matchedItemPath: string | null = null;

        menuItems.forEach((item) => {
            item.secondaryItems?.forEach((subItem) => {
                const fullSubPath = `${item.path}/${subItem.path}`;
                if (currentPath === fullSubPath) {
                    matchedItemPath = fullSubPath;
                }
            });

            if (!matchedItemPath && currentPath === item.path) {
                matchedItemPath = item.path;
            }
        });
        console.log('current path:', currentPath);
        console.log('matchedItemPath:', currentPath);

        if (matchedItemPath) {
            setActivePath(matchedItemPath);
        }
    }, [location.pathname, user]);

    const handleLogout = () => {
        dispatch(userSliceActions.removeUser())
        navigate('/auth')
    };


    return (
        <div className={`side-menu flex-column flex-gap-25 ${isOpen ? 'open' : ''}`}>
            <div
                className='logo-container flex-row flex-align-start-center flex-gap-20'
                onClick={() => navigate(`/app/dashboard`)}
            >
                {/* {isMerchant &&
                    <>
                        <XChargerIcon width={32} height={32} />
                        <span className='logo'>XCharger</span>
                    </>
                } */}

                <PayBridgeIcon width={30} height={30} />
                <span className='logo'>PayBridge</span>
            </div>
            <div className='user-info flex-row flex-align-start-center flex-gap-10'>
                <div className="user-avatar">
                    {user?.firstName?.charAt(0)}{user?.lastName?.charAt(0)}
                </div>
                <div className='user flex-row flex-wrap'>
                    <span>{user?.firstName}</span>
                    <span>{user?.lastName}</span>
                </div>
            </div>
            <div
                className='clock flex-row flex-gap-20 flex-align-start-center'
            >
                <ClockIcon />
                <span>{currentDateTime}</span>
            </div>
            <div className='menu-items flex-column flex-gap-20'>
                {
                    menuItems.map((item) => (
                        <div className='flex-column flex-gap-10' key={item.path}>
                            <div
                                className={`item flex-row flex-gap-20 flex-align-start-center ${activePath === item.path ? 'active' : ''
                                    }`}
                                onClick={() => {
                                    toggleMenu();
                                    navigate(item.path)
                                }}
                            >
                                {item.icon}
                                <span>{item.label}</span>
                            </div>
                            {item.secondaryItems && (
                                <div className='flex-column flex-gap-10' >
                                    {item.secondaryItems.map((subItem, subIndex) => {
                                        const fullSubPath = `${item.path}/${subItem.path}`;
                                        return (
                                            <div
                                                className={`item flex-row flex-gap-20 ${activePath === fullSubPath ? 'active' : ''}`}
                                                key={subIndex}
                                                onClick={() => {
                                                    toggleMenu();
                                                    navigate(`/app/${fullSubPath}`)
                                                }}
                                            >
                                                <div className="hidden">
                                                    {item.icon}
                                                </div>
                                                <span>{subItem.label}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ))
                }
            </div>
            <div
                className='logout flex-row flex-gap-20 flex-align-start-center'
                onClick={handleLogout}
            >
                <LogoutIcon />
                <span>Logout</span>
            </div>
        </div>
    )
}

export default SideMenu;