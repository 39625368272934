import React, { useEffect, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import AppFormInput from '../../../../components/form/app-form-input';
import AppFormSelect from '../../../../components/form/app-form-select';
import { AddMerchantForm, CommissionType, CommissionValueType } from './add-merchant.part.form';
import AddElementButton from '../../../../components/form/add-element-button';
import { ReactComponent as RemoveOutlineIcon } from '../../../../assets/icons/remove-outline.svg';
import './add-merchant.part.form.add-processor.scss'
import { ProcessorSecurityType, ProcessorType } from '../../../../types/processor.types';
import { apiService } from '../../../../services/api.service';
import AddMerchantPartFormCommissionField from './add-merchant.part.form.commission-field';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';




interface ProcessorFieldProps {
    form: UseFormReturn<AddMerchantForm>;
    processorIndex: number;
    removeProcessor: (index: number) => void;
}

interface ProcessorData {
    id: number,
    processorType: ProcessorType,
    processorName: string;
}

const ProcessorField: React.FC<ProcessorFieldProps> = ({
    form,
    processorIndex,
    removeProcessor,
}) => {
    const [processors, setProcessors] = useState<ProcessorData[]>();
    const { fields: midFields, append: addMID, remove: removeMID } = useFieldArray({
        control: form.control,
        name: `midList.${processorIndex}.midsDetails` as const,
    });
    const { user } = useSelector<AppStore, UserState>((state) => state.user);
    const isProcessorSelected = !!form.watch(`midList.${processorIndex}.processorId`);
    const {
        fields: commissionFields,
        append: addTransactionCommission,
        remove: removeTransactionCommission,
    } = useFieldArray({
        control: form.control,
        name: `midList.${processorIndex}.midsDetails.${midFields.length - 1}.commissions` as const,
    });


    useEffect(() => {
        const getProcessors = async () => {
            try {
                const response = await apiService.getAllProcessors();
                return response.data;
            } catch (e) {
                return []
            }
        }
        getProcessors().then((data) => {
            setProcessors(data)
        })
    }, [user])
    const processorsOptions = processors && processors?.map((processor) => {
        return {
            label: `${processor.processorName} (${processor.processorType})`,
            value: processor.id
        }
    })



    return (
        <>
            <div className="add-processor-form-container flex-row flex-gap-20 width-100">
                <div className='flex-1-form'>
                    <div className='flex-row flex-align-start-start flex-gap-5'>
                        <AppFormSelect
                            control={form.control}
                            name={`midList.${processorIndex}.processorId` as const}
                            options={processorsOptions || []}
                            placeholder={`Processor ${processorIndex + 1}`}
                            validations={{ required: true }}
                        />
                        {processorIndex != 0 &&
                            <RemoveOutlineIcon
                                width={35}
                                height={35}
                                fill='white'
                                className="remove-icon"
                                onClick={() => removeProcessor(processorIndex)}
                            />
                        }
                    </div>
                </div>
                {!isProcessorSelected && (
                    <div className="flex-2-form"></div>
                )}
                {isProcessorSelected && (
                    <div className="flex-column flex-gap-40 flex-2-form">
                        {midFields.map((mid, midIndex) => (
                            <div key={mid.id} className="mid-fields flex-column flex-gap-30">
                                <div className='flex-row flex-gap-20'>
                                    <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.midName` as const}
                                        placeholder="MID name"
                                        validations={{ required: true }}
                                    />
                                    <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.trashHoldLimit` as const}
                                        type="number"
                                        placeholder="Cap MID - $"
                                        validations={{ required: true }}
                                        hasCurrency={true}
                                    />
                                </div>
                                <div className="flex-row flex-gap-20">
                                    <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.md5` as const}
                                        placeholder="MD-5"
                                        validations={{ required: true }}
                                    />
                                    <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.privateKey` as const}
                                        placeholder="Public Key"
                                        validations={{ required: true }}
                                    />
                                </div>
                                <div className="flex-row flex-gap-20">
                                    <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.midCode` as const}
                                        placeholder="Account Code"
                                        validations={{ required: true }}
                                    />
                                      <AppFormInput
                                        control={form.control}
                                        name={`midList.${processorIndex}.midsDetails.${midIndex}.midDescriptor` as const}
                                        placeholder="MID Descriptor"
                                    />
                                    {midIndex != 0 &&
                                        <RemoveOutlineIcon
                                            width={50}
                                            height={50}
                                            fill='white'
                                            className='remove-icon'
                                            onClick={() => removeMID(midIndex)}
                                        />
                                    }
                                </div>
                                <div className="flex-row flex-gap-20 flex-wrap">
                                    {commissionFields.map((field, index) => (
                                        <AddMerchantPartFormCommissionField
                                            key={field.id}
                                            form={form}
                                            label={`Transaction Commission`}
                                            secondaryLabel={field.type}
                                            commissionName={`midList.${processorIndex}.midsDetails.${midIndex}.commissions.${index}` as const}
                                            removeCommission={() => removeTransactionCommission(index)}
                                        />
                                    ))}
                                    {/* <AddElementButton
                                        text="Add Transaction Commission"
                                        onClick={() =>
                                            addTransactionCommission({
                                                value: 0,
                                                valueType: CommissionValueType.PERCENT,
                                                type: CommissionType.CHARGEBACK,
                                                anyValue: false,
                                            })
                                        }
                                    /> */}
                                </div>
                            </div>
                        ))}

                        <AddElementButton
                            text="Add MID"
                            onClick={() => addMID({
                                midName: '',
                                md5: '',
                                midCode: '',
                                privateKey: '',
                                midDescriptor: '',
                                commissions: Object.values(CommissionType).map((type) => ({
                                    valueType: CommissionValueType.PERCENT,
                                    type,
                                    anyValue: false,
                                })),
                            })}
                        />
                    </div>
                )}
            </div >

        </>
    );
};

export default ProcessorField;
