import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchant.svg';
import { ReactComponent as CardIcon } from '../../../../assets/icons/processor-card.svg';
import { ReactComponent as CheckApproved } from '../../../../assets/icons/check-outline.svg';
import { ReactComponent as CheckRefund } from '../../../../assets/icons/check-refund.svg';
import { ReactComponent as CheckChargeBack } from '../../../../assets/icons/check-chargedback.svg';
import { ReactComponent as Declined } from '../../../../assets/icons/declined.svg';
import './transaction-details.part.transaction-information.scss';
import { TransactionDetails } from '../../../../types/transaction.types';
import { StatusType } from '../../../../types/statistics.types';
import React, {  useState } from 'react';
import dateService from '../../../../services/date.service';
import Button from '../../../../components/button';
import AppModalLogs from '../../../../components/app-modal-transaction-logs';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../../store/store';
import { UserState } from '../../../../store/user.slice';


const icons = {
    approved: <CheckApproved />,
    chargeback: <CheckChargeBack />,
    refunded: <CheckRefund />,
    declined: <Declined />
}

interface TransactionDetailsPartTransactionInformationProps {
    transactionDetails: TransactionDetails[];
    errorMessage?: string;
}

const TransactionDetailsPartTransactionInformation: React.FC<TransactionDetailsPartTransactionInformationProps> = ({ transactionDetails, errorMessage }) => {

    const { user } = useSelector<AppStore, UserState>((state) => state.user)

    const isMerchant = (user?.roles?.includes("merchant") || user?.roles?.includes("bas_merchant_view")) ?? false;

    const [isModalVisible, setModalVisible] = useState(false);

    const logs = transactionDetails.flatMap((transaction) =>
        transaction.logs
            .map((log) => log)
    );

    const handleCancel = () => {
        setModalVisible(false);
    };



    return (
        <div className="transaction-details-part-transaction-information flex-column flex-align-start-center">
            <div className="item entity">
                <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                    <UserIcon /> {transactionDetails[0].customerName}
                </div>
                <div className="secondaryText flex-row">
                    {transactionDetails[0].customerCardType} - xxxx xxxx xxxx {transactionDetails[0].customerLastFourCardDigits}
                </div>
            </div>

            {transactionDetails.map((transaction, index) => {
                return (
                    <React.Fragment key={transaction.id}>
                        <div className="separator"></div>

                        <div className={`item action ${transaction?.status?.toLowerCase()}`}>
                            <div>
                                <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                    <CardIcon /> {transaction.processorName}
                                </div>
                                {!isMerchant &&
                                <div className="secondaryText flex-row">
                                    { transaction?.processorTransactionId}
                                </div>
                                 }
                            </div>

                            {transaction.status !== StatusType.ERROR &&
                                icons[transaction?.status?.toLowerCase() as keyof typeof icons]}
                            {transactionDetails.length - 1 == index &&  !isMerchant &&
                                <div className={`info-container flex-column flex-gap-10`}>
                                    <Button label='View logs' onClick={() => setModalVisible(true)} />
                                </div>
                                
                            }
                          
                            <div className={`action-date flex-column flex-gap-5  ${transaction.status == StatusType.ERROR ? 'error' : ''}`}>
                                <span className={`title ${transaction.status.toLowerCase()}`}>
                                    {transaction.status === StatusType.APPROVED ||
                                        transaction.status === StatusType.REFUNDED ||
                                        transaction.status === StatusType.CHARGEBACK
                                        ? 'Successfully executed'
                                        : transaction.status === StatusType.ERROR
                                            ? 'Unexecuted'
                                            : transaction.status === StatusType.DECLINED
                                                ? 'Failed execution'
                                                : transaction.status === StatusType.PENDING
                                                    ? transaction.intermediateStatus
                                                    : ''}
                                </span>
                                {transaction.status !== StatusType.ERROR &&
                                    <span className='date'>
                                        {dateService.formatDateForTable(transaction.date)}
                                    </span>
                                }
                            </div>
                        </div>


                        {transaction.method == 'DEPOSIT' ? (
                            transaction.merchantName && <>
                                <div className="separator"></div>

                                <div className="item entity">
                                    <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                        <MerchantIcon /> {transaction.merchantName}
                                    </div>
                                    <div className="secondaryText flex-row">
                                        {transaction.transactionReferenceCode}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="separator"></div>
                                <div className="item entity">
                                    <div className="primaryText flex-row flex-align-start-center flex-gap-5">
                                        <UserIcon /> {transactionDetails[0].customerName}
                                    </div>
                                    <div className="secondaryText flex-row">
                                        {transactionDetails[0].customerCardType} - xxxx xxxx xxxx {transactionDetails[0].customerLastFourCardDigits}
                                    </div>
                                </div>
                            </>
                        )}
                        <AppModalLogs isVisible={isModalVisible} logs={logs} cancelAction={handleCancel} />
                    </React.Fragment>
                )
            })}
        </div>
    );
};

export default TransactionDetailsPartTransactionInformation;
